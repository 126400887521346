/* General styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.page-title {
    font-weight: 700;
    font-size: 28px;
    z-index: 0;
    text-align: center;
    padding: 20px;
}

/* Cart container */
.cart-container {
    display: grid;
    grid-template-columns: 1fr 470px;
    /* Desktop layout */
    align-items: start;
}

.header {
    text-align: center;
}

.cart-items {
    width: 95%;
    margin: 20px auto;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.cart-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.cart-item:last-child {
    border-bottom: none;
}

.item-image {
    width: 100px;

    object-fit: cover;
    margin-right: 20px;
}

.image-cart {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}

.delivery-date {
    font-size: 14px;
    color: green;
}

/* Product Details Section */
.product-details {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.quantity {
    display: flex;
    align-items: center;
    margin-top: 10px;
    max-width: 30%;
}

.quantity button {
    padding: 5px 10px;
    font-size: 18px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    cursor: pointer;
}

.quantity input {
    width: 40px;
    text-align: center;
    font-size: 16px;
    margin: 0 10px;
    border: 1px solid #ddd;
}

/* Button Styles */
.remove-item {
    background-color: red;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.place-order {
    background-color: rgb(95, 174, 16);
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.place-order:hover {
    background-color: rgb(67, 143, 8);
}

.remove-item:hover {
    background-color: #cc0000;
}

.cart-summary {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;
    padding: 15px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    padding-left: 22px;
    gap: 12px;
}

/* Checkout Button */
.checkout-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #0056b3;
}

.clear-cart {
    padding: 10px 20px;
    background-color: #ff5733;
    color: white;
    border: none;
    cursor: pointer;
}

.clear-cart:hover {
    background-color: #e74c3c;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .cart-container {
        grid-template-columns: 1fr 350px;
        /* Adjust for tablet sizes */
    }

    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .item-image {
        width: 80px;
        height: auto;
        /* Slightly reduce image size */
    }

    .cart-summary {
        margin-top: 20px;
        padding: 15px;
    }

    .checkout-button,
    .clear-cart {
        width: 100%;
        /* Full-width buttons */
    }
}

@media (max-width: 768px) {
    .cart-container {
        grid-template-columns: 1fr;
        /* Stack layout for mobile tablets */
        padding: 15px;
    }

    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-details {
        grid-template-columns: 1fr;
        /* Stack product details */
        padding: 15px;
    }

    .quantity {
        align-items: center;
        font-size: small;
        margin-bottom: 5px;
    }

    .checkout-button,
    .clear-cart {
        width: 100%;
        /* Full-width buttons */
    }

    .item-image {
        width: 70px;
        height: 70px;
        /* Adjust image size for mobile */
    }

    .cart-items {
        width: 100%;
        /* Full width */
    }
}

@media (max-width: 480px) {
    .page-title {
        font-size: 24px;
        /* Smaller font for mobile */
    }

    .cart-summary {
        width: 100%;
        margin: 0;
    }

    .checkout-button,
    .clear-cart {
        font-size: 14px;
        /* Reduce font size */
    }

    .item-image {
        width: 60px;
        /* Smaller images on small screens */
        height: 60px;
    }

    .item-image:hover {
        transform: scale(1.2);
    }

    .cart-container {
        display: flex;
        flex-direction: column-reverse;
        /* Reverse the order for small screens */
    }

    .cart-item {
        flex-direction: column;
    }

    .product-details {
        padding: 10px;
        /* Adjust padding for small screens */
    }
}