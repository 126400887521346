/* General container */
.order-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
}

.order-header {
    text-align: center;
    margin-bottom: 30px;
}

.order-header h1 {
    font-size: 2rem;
    color: #333;
}

/* Each order item */
.order-item-container {
    border: 1px solid #ddd;
    margin-bottom: 25px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.order-summary {
    margin-bottom: 20px;
}

/* Order overview styling */
.order-overview h2 {
    margin-top: 0;
    font-size: 1.5rem;
}

.order-overview p {
    font-size: 1rem;

}

/* Status tracker */
.order-status-tracker {
    display: flex;
    justify-content: space-between;

}

.status-step {
    padding: 3px;
    background-color: #f0f0f0;
    border-radius: 5px;
    text-align: center;
    flex-grow: 1;
}

.status-step.completed {
    background-color: #4caf50;
    color: white;
}

/* Order Items Section */
.order-items {
    margin-top: 20px;
}

.order-items h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.order-items ul {
    list-style-type: none;
    padding-left: 0;
}

.order-item {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 15px;
}

.item-info {
    flex-grow: 1;
}

.item-info h4 {
    margin: 0;
    font-size: 1rem;
}

.item-info p {
    font-size: 0.9rem;
    margin: 5px 0;
}

/* Shipping Information */
.shipping-info {
    margin-top: 25px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.shipping-info h3 {
    font-size: 1.25rem;
    margin-bottom: 15px;
}

.shipping-info p {
    font-size: 1rem;
    margin: 5px 0;
}