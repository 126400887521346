/* Footer Styles */
.footer {
    background-color: #52c6fc;
    /* Light blue background */
    color: white;
    /* Text color white */
    padding: 40px 20px;
    /* Padding around the footer */
    text-align: center;
    /* Center-align text */
    font-family: Arial, sans-serif;
    /* Set font style */
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    /* Distribute the sections evenly */
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    /* Center align the content */
    padding: 0 20px;
}

.footer-left,
.footer-center,
.footer-right {
    flex: 1;
    text-align: center;
}

.footer-left {
    font-size: 14px;
    color: #fff;
}

.footer-center .footer-links,
.footer-right .social-links {
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    margin: 0;
}

.footer-links li,
.social-links li {
    display: inline-block;
    /* Display links horizontally */
    margin: 0 15px;
    /* Space out each link */
}

.footer-links a,
.social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.footer-links a:hover,
.social-links a:hover {
    text-decoration: underline;
    /* Underline links when hovered */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        /* Stack sections vertically */
        align-items: center;
    }

    .footer-left {
        margin-bottom: 10px;
        /* Space between sections */
    }

    .footer-center,
    .footer-right {
        margin-top: 10px;
    }

    .footer-links li,
    .social-links li {
        margin: 5px 10px;
        /* Adjust spacing on small screens */
    }

    .footer-left {
        font-size: 12px;
    }

    .footer-links a,
    .social-links a {
        font-size: 14px;
    }
}

/* Styling for social media links */
.social-links a {
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.social-links a:hover {
    background-color: #ffffff;
    color: #52c6fc;
    /* Color changes on hover */
}

/* Bottom space for clarity */
.footer-bottom {
    margin-top: 20px;
}