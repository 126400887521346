/* General Styles */
body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    /* Fallback font stack */
    position: relative;
}

/* Root variables for colors and fonts */
:root {
    --primary-color: #aed5ff;
    --secondary-color: #fff;
    --hover-background: rgba(43, 144, 180, 0.6);
    --text-hover-background: linear-gradient(rgb(7, 31, 59), rgb(250, 21, 82));
    --logo-size: 60px;
}

/* Header styles */
.header {
    background-color: #2c3e50;
    box-shadow: 0 1px 3px skyblue;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 1px 3px skyblue;
    z-index: 1000000;





}

/* Left side of the header */
.left {
    display: flex;
    align-items: center;
    height: 80px;
    color: rgb(21, 54, 82);
}

.lucky-name {
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    background-image: linear-gradient(rgb(66, 143, 231), rgb(143, 30, 60));
    background-size: cover;
    color: transparent;
    background-clip: text;
    cursor: pointer;
    margin-left: 10px;
    /* Adds space between logo and name */
}

.lucky-name:hover {
    background-image: var(--text-hover-background);
    transition: 0.1s;
}

.lucky-logo {
    height: var(--logo-size);
}

/* Navigation styles */
.nav {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.nav-item {
    padding: 10px;
    text-decoration: none;
    color: white;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
}

.nav-item:hover {
    border-bottom: 2px solid white;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    aria-label: "Toggle Navigation";
    /* Accessibility */
}

.bar {
    height: 4px;
    width: 25px;
    background-color: white;
    margin: 3px 0;
    transition: 0.3s;
}

.open {
    transform: rotate(180deg);
}

.open+.bar {
    transform: rotate(0deg);
}

.open+.bar+.bar {
    transform: rotate(-20deg);
}

/* Dropdown button styles */
.dropbtn {
    background-color: transparent;

    font-size: 16px;
    border: none;
    outline: none;
    margin: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.dropbtn p {
    display: none;
}

.dropbtn:hover {
    background-color: var(--hover-background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    transform: scale(1.05) perspective(500px);
}





.dropdown-select {
    position: absolute;
    top: 0%;
    /* Place it directly below the button */
    left: 0;
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000000000000000;
}

.dropdown-select li {
    padding: 5px;

}


/* Mobile view adjustments */
@media (max-width: 768px) {
    .header {
        display: flex;
        justify-content: space-between;
        position: fixed;
    }

    .nav {
        transform: translateX(-100%);
        transition: transform 1s ease;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #2c3e50;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        top: 80px;
        height: 100vh;
    }

    .nav.open {
        transform: translateX(0);
        transition: transform 1s ease-in-out;
        top: 80px;
        height: 100vh;
        color: white;
        width: 250px;
        background-color: #2c3e50;
    }



    .hamburger {
        display: flex;
        padding-right: 12px;
    }

    .middle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: max-content;
        text-align: left;
    }

    .lucky-logo {
        height: 50px;
    }

    .middle .dropbtn {
        background: none;
        margin: 0;
        border-bottom: 2px solid black;
        width: 200px;
        text-align: left;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        box-shadow: none;
    }

    .dropbtn p {
        display: block;
    }
}

/* Styling for links in the header */
.header-link {
    display: inline-block;
    padding: 6px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid transparent;
}

.header-link:hover {
    border: 1px solid white;
}

/* Dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 90px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 4px 8px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Hover effects for dropdown links */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.right:hover .dropdown-content {
    display: block;
}



.image {
    position: relative;
    overflow: hidden;
}

.slideImages:hover {
    cursor: pointer;


}