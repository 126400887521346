/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Make sure the modal is on top of other elements */
}

/* Modal Content */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

}

/* Modal Title */
.modal-content h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

/* Form Fields */
.modal-content input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

/* Labels */
.modal-content label {
    font-size: 14px;
    font-weight: bold;
}

/* Modal Buttons Container */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Cancel Button */
.modal-buttons button:first-child {
    background-color: #ccc;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Save Button */
.modal-buttons button:last-child {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button Hover Effects */
.modal-buttons button:hover {
    opacity: 0.8;
}

/* Close Button */
.modal-buttons button:first-child:hover {
    background-color: #bbb;
}

.modal-buttons button:last-child:hover {
    background-color: #45a049;
}