/* General Container */
.container {
    display: flex;
    padding: 20px;
    max-width: 800px;

    flex-wrap: wrap;
    margin-top: 80px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

/* Login Form */
.login-form {
    flex: 1;
    padding: 20px 40px;
    max-width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    /* White background for form */
    border-radius: 8px;
    /* Rounded corners for the form */
}

/* Form Header */
.login-form h2 {
    margin-bottom: 10px;
    font-size: 1.8rem;
    color: #333;
}

/* Login Paragraph */
.login-form p {
    text-align: center;
    color: green;
    font-size: 1rem;
}

/* Form Group Styling */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

/* Input Fields */
.form-group input {
    width: 90%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    border-color: #007BFF;
    outline: none;
}

/* Submit Button */
.submit-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}

.submit-button:disabled {
    background-color: #a6c8ff;
    /* Lighter color for disabled state */
    cursor: not-allowed;
}

/* Forgot Password Link */
.forgot-password {
    display: block;
    margin-top: 10px;
    color: #007BFF;
    text-decoration: none;
}

.forgot-password:hover {
    text-decoration: underline;
}

/* Success/Error Message Styling */
.error-message,
.success-message {
    color: rgb(241, 28, 28);

    /* Red for error */
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    text-align: center;
}

.success-message {
    color: #4CAF50;
    /* Green for success */
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.modal-content h3 {
    font-size: 1.5rem;
    color: #333;
}

.modal-content button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #0056b3;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        margin-top: 40px;
        /* Less top margin on smaller screens */
        padding: 15px;
    }

    .login-form {
        max-width: 100%;
        padding: 20px;
    }

    .form-group input {
        width: 100%;
        /* Full width for input fields on mobile */
    }

    .submit-button {
        width: 100%;
        /* Full width for submit button on mobile */
    }

    .forgot-password {
        text-align: center;
        margin-top: 20px;
    }

    .error-message,
    .success-message {
        font-size: 1rem;
        padding: 8px;
    }
}