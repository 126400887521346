.admin-dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.section {
    margin-bottom: 40px;
}



.admin-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.admin-table th,
.admin-table td {
    padding: 2px 2px 2px 12px;
    border: 1px solid #ccc;
    text-align: left;
}

.admin-table th {
    background-color: #f2f2f2;
}

td button {
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #ddd;
}

.link {
    text-decoration: none;
    color: #007BFF;
    font-size: 14px;
}

.link:hover {
    text-decoration: underline;
}