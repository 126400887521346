/* General Styles */
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

h2,
h3 {
  margin: 0;
  font-weight: 600;
}

/* Lucky Details Section */
.lucky-details {
  position: absolute;
  top: 70px;
  right: 100px;
  font-size: 20px;
}

.lucky-head {
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(rgb(231, 80, 66), rgb(143, 30, 60));
  color: transparent;
  background-clip: text;
  text-transform: capitalize;
}

.lucky-para {
  font-size: 16px;
}

.home-image {
  display: none;
}

/* Container that holds the image wrapper */
.image-move {
  display: flex;
  width: 100%;
  height: auto;

  overflow: hidden;
}

/* Wrapper that holds all the images */
.image-wrapper {

  display: flex;
  animation: moveImage 35s linear infinite;
  /* Increased duration to accommodate pauses */
}

/* Adjust the images to be responsive */
.image-wrapper img {
  width: 100%;

  height: auto;



}

/* Pause the animation when hovering over the container */
.image-move:hover .image-wrapper {
  animation-play-state: paused;
}

/* Keyframes to move the images with pauses between moves */
@keyframes moveImage {
  0% {
    transform: translateX(0);
    /* First image visible */
  }

  15% {
    transform: translateX(0);
    /* Pause for the first image */
  }

  20% {
    transform: translateX(-100%);
    /* Second image visible */
  }

  35% {
    transform: translateX(-100%);
    /* Pause for the second image */
  }

  40% {
    transform: translateX(-200%);
    /* Third image visible */
  }

  55% {
    transform: translateX(-200%);
    /* Pause for the third image */
  }

  60% {
    transform: translateX(-300%);
    /* Fourth image visible */
  }

  75% {
    transform: translateX(-300%);
    /* Pause for the fourth image */
  }

  80% {
    transform: translateX(-400%);
    /* Fifth image visible */
  }

  95% {
    transform: translateX(-400%);
    /* Reset to the first image */
  }

  100% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .image-move {
    height: auto;

  }

  .image-wrapper {
    width: 500%;
    /* The width still remains the same, but images adjust within it */
  }

  .image-wrapper img {
    height: auto;
  }
}

@media (max-width: 480px) {
  .image-move {
    height: auto;
  }

  .image-wrapper {
    margin-top: 80px;
    width: 500%;
    /* Keep the same width */
  }


}








/* Button Row (navigation) */
.button-row {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: rgb(21, 54, 82);
  color: white;
  font-size: 18px;
}

/* Category List Grid */
.category-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
  background-color: rgb(21, 54, 82);
  color: white;
  justify-items: center;
}

.category-item {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.category-item p {
  font-size: 1rem;
  color: #555;
}

.category-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.category-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #028f87;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-button:hover {
  background-color: #025e56;
}

/* About Section */
.about-section {
  padding: 40px;
  background-color: #f4f4f4;
  text-align: center;
  margin: 20px 0;
  border-radius: 8px;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.about-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Hero Section */


.hero-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  padding: 15px 30px;
  background-color: #FFC107;
  color: #028f87;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #ff9f00;
}

/* Promotional Banner */
.promo-banner {
  background-color: #028f87;
  color: white;
  text-align: center;
  padding: 20px;
  opacity: 0.75;

}

.promo-banner p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.promo-banner h1 {
  color: #0000007b;
  font-size: 32px;
  animation: moveRight 2s ease-in-out;
}

.promo-banner-details {
  animation: moveLeftb 2s ease-in-out;
}

.promo-button {
  padding: 10px 20px;
  background-color: #FFC107;
  color: #028f87;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.promo-button:hover {
  background-color: #ff9f00;
}

.brands {

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;


}

.brands span {

  width: max-content;
  display: inline-block;

  font-weight: bold;
  padding: 0px 50px;
  background-image: linear-gradient(rgb(126, 178, 237), rgb(228, 92, 128));

  animation: moveLeftbra 20s ease-in-out infinite;
  /* Apply the animation */
}

/* Keyframes to create the left-to-right movement */
@keyframes moveLeft {
  0% {
    transform: translateX(100%);
    /* Start off-screen on the right */
  }

  100% {
    transform: translateX(0%);
    /* End off-screen on the left */
  }
}

@keyframes moveLeftb {
  0% {
    transform: translateX(100%);
    /* Start off-screen on the left */
  }

  100% {
    transform: translateX(0%);
    /* End off-screen on the right */
  }

}

@keyframes moveRight {
  0% {
    transform: translateX(-100%);
    /* Start off-screen on the right */
  }

  100% {
    transform: translateX(0%);
    /* End off-screen on the left */
  }
}


/* Testimonials Section */
.testimonials {
  background-color: #f9f9f9;
  padding: 50px 0;
  text-align: center;
}

.testimonial {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 350px;
  text-align: center;
  margin: 10px;
}



.brand-list-items {
  display: flex;

  gap: 15px;

  border-inline-start-color: #028f87;

  flex-wrap: wrap;
  justify-content: center;

  list-style-type: none;
  padding: 0;
  overflow-y: auto;

}


/* Define the keyframe animation */
@keyframes moveLeftbra {
  0% {
    transform: translateX(100%);
    /* Start from the right off-screen */
  }

  50% {
    transform: translateX(0);
    /* Move to normal position (center) */
  }

  100% {
    transform: translateX(-100%);
    /* Move to the left off-screen */
  }
}


.brand-list-items li {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px 20px;
}

.brand-list-items a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: bold;
  display: block;
}

.brand-list-items li:hover {
  background-color: #546374bb;
  color: white;
}

button.brand-logo {
  padding: 6px 8px;
  background-color: rgba(148, 145, 145, 0.793);
  margin: 0%;
  box-shadow: 2px 2px 4px crimson;
  border-block-color: unset;
  border-block-end-style: revert;
}

button.brand-logo:hover {
  background-color: #5f5d5dd4;
}

.brand-list-items .brand-logo img {
  width: 100%;
  height: 33px;
  background-color: transparent;
}


/* Footer */
.footer {
  background-color: hsl(192, 100%, 9%);
  color: white;
  gap: 60px;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.footInfo {
  display: flex;
  gap: 20px;
}

.left-section h2 {
  font-size: 24px;
  text-transform: capitalize;
  color: #FFC107;
}

.right-section {
  padding: 10px;
  border-left: 2px solid black;
  margin-top: 15px;
}

.about-us,
.why-choose-us {
  padding: 10px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.email,
.phone,
.whatsapp {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email img,
.phone img,
.whatsapp img {
  width: 20px;
  height: 20px;
}

.hero-section {
  display: flex;
  flex-direction: column;
}

.footer .foot {
  background-color: #140e0e;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: larger;
  color: #fff;
}

/* Slider Radio Buttons */
.slider-radio-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.slider-radio {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider-radio:checked {
  background-color: white;
}

.brand-list {
  overflow: hidden;
}

.card-container h1 {
  margin: 0%;
  font-size: 1.6rem;
}

/* Responsive Design */
@media (max-width: 600px) {

  /* Adjustments for smaller screens */
  .lucky-details {
    display: none;
  }



  .home-image {
    display: block;
  }

  .brands {
    top: 95%;
  }

  .category-list {
    grid-template-columns: 1fr 1fr;
    padding: 20px 3px;
    gap: 8px;
  }

  .category-button {
    padding: 4px 6px;
  }

  .category-item p {
    font-size: 10px;
    text-align: left;
  }

  .brand-list-items {
    display: flex;

    animation: moveLeftbra 20s ease-in-out infinite;

  }

  .brand-logo {
    width: 80px;
  }

  .category-item h3 {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .promo-banner {
    padding: 2px;
  }

  .category-item {
    width: 100%;
    padding: 6px;

  }



  .slider-radio-buttons {
    bottom: 10px;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hero-section h1 {
    font-size: 1.5rem;
  }

  .left-section {
    width: 100%;
    padding: 10px;
  }

  .about-section {
    padding: 30px;
  }
}



.foot {
  background-color: #555;
}

.footer-p {
  text-align: center;
  color: #fff;
}

@media (max-width: 800px) {

  /* Adjustments for smaller screens */
  .lucky-details {
    display: none;
  }

  .home-image {
    display: block;
  }

  .category-list {
    grid-template-columns: 1fr 1fr;
    padding: 20px 3px;
    gap: 8px;
  }

  .category-button {
    padding: 4px 6px;
  }

  .category-item p {
    font-size: 10px;
    text-align: left;
  }

  .brand-list-items {
    display: flex;

    animation: moveLeftbra 20s ease-in-out infinite;

  }

  .brand-logo {
    width: 80px;
  }

  .category-item h3 {
    font-size: 14px;
    margin-bottom: 2px;
  }



  .category-item {
    width: 100%;
    padding: 6px;

  }



  .slider-button,
  .slider-button1 {
    width: 40px;
    height: 40px;
  }

  .slider-radio-buttons {
    bottom: 10px;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hero-section h1 {
    font-size: 1.3rem;
    color: cornsilk;

  }

  .promo-banner p {
    font-size: .8rem;
  }

  .left-section {
    width: 100%;
    padding: 10px;
  }

  .about-section {
    padding: 30px;
  }
}

.offers-list {
  display: flex;
  justify-content: space-around;



}

.offers-list .offer {

  text-decoration: none;
  width: auto;
  padding: 5px 5px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  background-color: #25fa3e;
  transform: 0.5s linear;
}

.offers-list .offer:hover {
  background-color: #31f43ec8;
}

@media (max-width: 600px) {
  .offers-list .offer {
    width: 100%;
    padding: 4px;
    font-size: 0.7rem;
  }
}