/* Base styling */
.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;

}

.contact-header {
    text-align: center;
    margin-bottom: 30px;
}

.contact-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.contact-header p {
    color: #666;
    font-size: 1rem;
}

/* Container for left and right contact sections */
.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    gap: 40px;
    margin-bottom: 50px;
    /* Adding bottom margin for spacing */
}

.contact-left,
.contact-right {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-left {
    width: 40%;
}

.contact-right {
    width: 55%;
}

.contact-left h2,
.contact-right h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
}

.contact-left p,
.contact-right p {
    color: #777;
    font-size: 1rem;
    margin-bottom: 20px;
}

.contact-left ul {
    list-style: none;
    padding: 0;
    color: #555;
}

.contact-left ul li {
    font-size: 1rem;
    margin-bottom: 10px;
}

.contact-left ul li strong {
    color: #333;
}

/* Form and Input Styles */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 1rem;
    color: #333;
    margin-bottom: 8px;
}

.form-control {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

textarea.form-control {
    resize: vertical;
}

/* Button Styles */
.btn-submit {
    width: 100%;
    padding: 12px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #0056b3;
}

.btn-submit:focus {
    outline: none;
    background-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Success/Failure message styles */
.status-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
    text-align: center;
}

.status-message.success {
    background-color: #d4edda;
    color: #155724;
}

.status-message.error {
    background-color: #f8d7da;
    color: #721c24;
}

/* Social Media Icons Section */
.social-media {
    text-align: center;
    margin-top: 40px;
    width: 100%;
}

.social-media h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 2rem;
}

.social-icons a {
    color: #333;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #007bff;
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .contact-left,
    .contact-right {
        width: 90%;
    }

    .social-icons {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .contact-header h1 {
        font-size: 2rem;
    }

    .contact-left h2 {
        font-size: 1.8rem;
    }

    .contact-left ul li {
        font-size: 0.9rem;
    }

    .social-icons {
        font-size: 1.3rem;
        gap: 10px;
    }
}