.map-container {
    text-align: center;
    margin: 20px;
}

h1 {
    font-size: 2rem;
    color: #333;
}

h3 {
    color: #555;
}

.zoom-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.zoom-btn:hover {
    background-color: #0056b3;
}

/* Make the map responsive */
@media (max-width: 768px) {
    .map-container {
        margin: 10px;
    }

    .zoom-btn {
        width: 100%;
    }
}