/* Notification Styles */
.notification-container {
    position: fixed;
    top: 75px;
    right: 0;
    width: auto;
    z-index: 9999999999999999999999999;
}

.notification {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s, fadeOut 0.5s;
}

.notification.success {
    background-color: #4CAF50;
    color: white;
}

.notification.error {
    background-color: #f44336;
    color: white;
}

.notification h4 {
    margin: 0;
}

.notification p {
    margin: 5px 0;
}

.notification button {
    position: absolute;
    background: none;
    border: none;
    color: black;
    font-size: 16px;
    cursor: pointer;
    z-index: 999999999999999999999999999999999999999999999999;
    top: 0px;
    right: 0px;
    font-weight: 700;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}