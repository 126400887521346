/* General layout styling */
.feedback-list {

    padding: 20px 20px;
    background-color: #f9f9f9;
}

.feedback-head {
    display: flex;
    flex-direction: column;
    text-align: center;

    font-family: 'Roboto', sans-serif;
}

.feedback-head h1 {
    font-size: 2.5rem;
    color: #004b87;
    /* Company primary color */
    font-weight: 700;
    margin-bottom: 10px;
}

.feedback-head p {
    font-size: 1.2rem;
    color: #555;
    font-weight: 400;
}

/* Table container */
.feedback-table-container {

    overflow-x: auto;
}

/* Table Styling */
.feedback-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.feedback-table th,
.feedback-table td {
    padding: 15px;
    text-align: left;
    font-size: 1.1rem;
    color: #333;
}

.feedback-table th {
    background-color: #004b87;
    /* Company primary color */
    color: white;
    font-weight: 600;
}

.feedback-table td {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}

/* Hover effect for rows */
.feedback-table-row:hover {
    background-color: #f1f1f1;
}

.feedback-table-cell {
    word-break: break-word;
}

/* Loading state styling */
.loading {
    text-align: center;
    margin-top: 50px;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #004b87;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Error message styling */
.error-message {
    color: #e74c3c;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .feedback-head h1 {
        font-size: 2rem;
    }

    .feedback-list {
        padding: 0%;
    }

    .feedback-head p {
        font-size: 1rem;
    }

    .feedback-table th,
    .feedback-table td {
        padding: 5px;
        font-size: 0.6rem;
    }

    .feedback-table {
        font-size: 0.9rem;
    }
}

.feedback-email-link {
    color: #004b87;
    text-decoration: none;
    font-weight: 500;
}


.feedback-email-link:hover {
    text-decoration: underline;
    color: #0073e6;
}