.manage-products {
    font-family: Arial, sans-serif;
    padding: 20px;
}

form {
    margin-bottom: 20px;
}

input,
textarea {
    display: block;
    margin: 10px 0;
    padding: 8px;
    width: 100%;
}

button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
}

.product-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin: 10px;
    width: 200px;
}

.actions button {
    margin-right: 10px;
}