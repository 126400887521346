/* OrderComponent.css */
.order-container {
    padding: 20px;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
}

.orders-table th,
.orders-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

button {
    padding: 8px 12px;
    margin: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #f0f0f0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: red;
    font-size: 18px;
    text-align: center;
}