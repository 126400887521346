/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

:root {
    --primary-color: #007bff;
    --secondary-color: #388e3c;
    --danger-color: #e53935;
    --background-color: #f9f9f9;
    --text-color: #333;
    --muted-text-color: #888;
    --error-color: #d32f2f;
    --light-gray: #f4f4f4;
    --border-radius: 12px;
    /* Slightly rounded corners for more modern feel */
    --max-width: 320px;
    --btn-padding: 6px 12px;
    --search-bar-height: 40px;
}

body {
    background-color: var(--background-color);
    font-size: 16px;
    line-height: 1.3;
    color: var(--text-color);
}

/* Product Grid Styles */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-items: center;
    transition: all 0.3s ease;
}

.product-container {
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: var(--max-width);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
    text-align: left;
}

.product-container:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.product-image-container {
    width: 100%;
    overflow: hidden;
    background-color: var(--light-gray);
    border-radius: var(--border-radius);
}

.product-image {
    max-width: 100%;
    height: 250px;
    /* Fixed height for consistency */
    object-fit: cover;
    transition: transform 0.5s ease;
}

.product-container:hover .product-image {
    transform: scale(1.05);
}

/* Product Name and Text */
.product-name {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
    line-height: 1.4;
    text-align: left;
    margin-top: 10px;
    transition: color 0.3s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-name:hover {
    color: var(--primary-color);
    cursor: pointer;
}

/* Price and Discount Styles */
.product-mrp,
.product-discount,
.product-price {
    font-size: 1rem;
    font-weight: 500;
    padding: 0px 0;
    color: var(--text-color);
}

/* Loading Container Styles */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height of the viewport */
    background-color: rgba(0, 0, 0, 0.1);
    /* Light background overlay */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    /* Ensures the loader appears above other content */
}




/* Spinner GIF Style */
.spinner-gif {
    width: 50px;
    /* Adjust size if necessary */
    height: 50px;
}

/* Keyframe Animation for Spinner */


.product-mrp {
    text-decoration: line-through;
    color: #999;
}

.product-discount {
    color: var(--secondary-color);
    font-weight: 600;
}

.product-price {
    color: var(--primary-color);
    font-weight: 600;
}

/* Add to Cart Button */
.add-to-cart-button {
    display: block;
    width: 100%;
    padding: var(--btn-padding);
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-to-cart-button:hover {
    background-color: #0056b3;
}

.add-to-cart-button:disabled {
    background-color: #bbb;
    cursor: not-allowed;
}

/* Search Bar */
.search-bar {
    position: absolute;
    top: 30vh;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 60%;
    border-radius: 30px;
    border: 2px solid #ddd;
    font-size: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    height: var(--search-bar-height);
    z-index: 1;
    opacity: 0.7;
    margin: 0%;
}

.search-bar:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    outline: none;
}

/* Image Slider */
.slider-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: opacity 0.3s ease;

}

/* Loading and Error Messages */
.loading-container,
.error-container {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;

}

.error-container {
    color: var(--error-color);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .product-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 15px;
    }

    .product-container {
        max-width: 280px;
        padding: 15px;
    }

    .product-name {
        font-size: 1.1rem;
    }

    .slider-image {
        height: 100%;
        margin-top: 60px;
    }

    .search-bar {
        padding: 10px 20px;
    }
}

@media (max-width: 680px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 5px;
    }

    .product-container {
        max-width: 100%;
        padding: 10px;
    }

    .product-image-container {
        height: auto;
    }

    .product-image {
        width: 100%;
        height: fit-content;
    }

    .product-name {
        font-size: 1rem;

    }

    .product-mrp,
    .product-price,
    .product-discount {
        font-size: 1rem;
        font-weight: 500;
    }

    .add-to-cart-button {
        margin: 0;
        padding: 8px 0;
    }

    .search-bar {
        width: 90%;
        font-size: 0.9rem;


    }

    .product-actions {
        display: flex;
        justify-content: space-around;
        max-width: fit-content;
        padding: 0%;
        margin: 0px;
    }

    .product-actions button {
        margin: 6px;
        padding: 6px 6px;
    }
}

.product-actions {
    display: flex;
    justify-content: space-around;

    padding: 0%;
    margin: 0px;
}



.edit-btn {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;

}

.delete-btn {
    background-color: #f44336;
    border-radius: 5px;
}

.delete-btn:hover {
    background-color: #ff9800;
}

.stock {

    font-size: 14px;
}

.stock-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;


}

.product-model {
    font-size: 14px;
    display: inline-block;
}

.in-stock {
    background-color: green;
}

.out-of-stock {
    background-color: red;
}

@media (max-width: 680px) {
    .product-model {
        font-size: 11px;

    }

    .product-size {

        font-size: 11px;
    }

    .stock {
        font-size: 11px;
    }
}