/* Container for the complaints list */
.complaints-container {
    margin: 20px auto;
    padding: 20px;
    width: 90%;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Table styling */
.complaints-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

h3 {
    text-align: center;
    font-size: 24px;
}

/* Table header styling */
.complaints-table th {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    text-align: left;
    font-size: 16px;
}

/* Table cell styling */
.complaints-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

/* Row hover effect */
.complaints-table tr:hover {
    background-color: #f1f1f1;
}

/* Loading and error messages */
.loading-message,
.error-message {
    text-align: center;
    font-size: 18px;
    color: #555;
}

/* Error styling */
.error-message {
    color: red;
}

/* No complaints message */
.no-complaints {
    text-align: center;
    font-size: 18px;
    color: #888;
}

/* --- RESPONSIVE STYLES --- */

/* Mobile view: Stack the table content vertically on small screens */
@media screen and (max-width: 600px) {

    /* Adjusting container width */
    .complaints-container {
        width: 100%;
        padding: 15px;
    }

    /* Table styling for small screens */
    .complaints-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    /* Table header for mobile */
    .complaints-table th {
        font-size: 14px;
    }

    /* Table cell adjustments for smaller screens */
    .complaints-table td {
        font-size: 12px;
        padding: 8px;
    }

    /* Adjusting heading sizes */
    h3 {
        font-size: 20px;
    }
}

/* Tablet view: Adjust layout for medium-sized screens (up to 768px) */
@media screen and (max-width: 768px) {
    .complaints-container {
        width: 95%;
    }

    .complaints-table th {
        font-size: 15px;
    }

    .complaints-table td {
        font-size: 13px;
        padding: 10px;
    }

    h3 {
        font-size: 22px;
    }
}

/* Desktop view: Keep the layout as is for larger screens */
@media screen and (min-width: 769px) {
    .complaints-container {
        width: 100%;
    }

    .complaints-table th {
        font-size: 16px;
    }

    .complaints-table td {
        font-size: 14px;
        padding: 12px;
    }

    h3 {
        font-size: 24px;
    }
}