/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: auto;
}

/* Modal Content */
.modal-content {
    background-color: white;
    padding: 20px;
    width: 600px;
    border-radius: 8px;
    position: relative;
    height: 80vh;
    overflow: auto;
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: #aaa;
    cursor: pointer;
}

.close-btn:hover {
    color: black;
}

/* Form Styling */
.form-container {
    display: flex;
    flex-direction: column;
}

.form-container input,
.form-container button {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.form-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.form-container button:hover {
    background-color: #45a049;
}

.success-message {
    color: green;
    font-size: 18px;
}

.error-message {
    color: red;
    font-size: 18px;
}